
import { defineComponent, watch } from 'vue'
import { loadTermsOfUseContent } from '@/config/documentLoader'

import Header from '@/components/Home/Header.vue'
import setMeta from '@/scripts/root/setMeta'
import content from './Content.vue'

export default defineComponent({
  components: {
    Header,
  },
  data() {
    return {
      termsOfUseContent: '',
    }
  },
  computed: {
    currentLanguage(): string {
      return this.$store.getters.getAppLanguage
    },
  },
  methods: {
    setMeta,
    content,
    formattedValue(value: string) {
      // 줄바꿈을 <br/>로 변환
      let formattedText = value.replace(/\n/g, '<br/>')
      // Markdown 링크를 HTML 링크로 변환하며, 'dynamic-link' 클래스를 추가합니다.
      return formattedText.replace(
        /\[(.*?)\]\((.*?)\)/g,
        '<a href="$2" target="_blank" class="dynamic-link">$1</a>'
      )
    },
  },
  watch: {
    currentLanguage(newVal, oldVal) {
      this.termsOfUseContent = loadTermsOfUseContent(newVal)
    },
  },
  mounted() {
    this.termsOfUseContent = loadTermsOfUseContent(this.currentLanguage)
  },
})
