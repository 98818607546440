import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f46e579"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title-page" }
const _hoisted_2 = { class: "content-title" }
const _hoisted_3 = { class: "key" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.termsOfUseContent, (content, title) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "content-block",
          key: title
        }, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(title), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(content, (value, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: "key-value-pair"
            }, [
              _createElementVNode("strong", _hoisted_3, _toDisplayString(key), 1),
              _createElementVNode("p", {
                class: "value",
                innerHTML: _ctx.formattedValue(value)
              }, null, 8, _hoisted_4)
            ]))
          }), 128))
        ]))
      }), 128))
    ])
  ], 64))
}